import { useExlyDataProvider } from "data/useExlyDataProvider";
import email_drip_api from "./EmailDrip.api";

export const useEmailDrip = () => {
  const dataProvider = useExlyDataProvider();

  const resumeEmailDrip = async (drip_uid) => {
    const { data, status } = await dataProvider.custom_request(
      email_drip_api.resume_email_drip,
      "POST",
      { drip_uid },
      { checkCreditsExpiry: true }
    );
    if (status !== 200)
      throw new Error(`Error resuming email drip: ${data} ${status}`);
    return data;
  };

  return {
    resumeEmailDrip,
  };
};

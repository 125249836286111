import { getEmailPreviewFromHtmlString } from "utils/htmlParser";
import {
  getSchedulePreview,
  getTimeBasedCategoryType,
} from "../EmailDripFlow/EmailDripFlow.utils";

export const emailDetailApiKeys = {
  body: "email_body",
  subject: "email_subject",
};

export const getFieldConfig = ({ dripDetails }) => {
  const timeBasedCategoryType = getTimeBasedCategoryType(dripDetails);

  return [
    {
      label: "Message",
      format: (data) =>
        getEmailPreviewFromHtmlString(data.email_body, data.meta_info),
      className: "line_max_1",
      contentClassName: "line_max_1",
    },
    {
      label: "Scheduled for",
      format: (data) => getSchedulePreview({ ...data, timeBasedCategoryType }),
    },
  ];
};

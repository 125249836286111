import React, { useEffect, useState } from "react";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import qs from "query-string";

import useHideNavAndAppBar from "hooks/useHideNavAndAppBar";
import { GoBack } from "common/Components/Header";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import style from "./AnalyticsPerEmail.module.css";
import { fetchEmailDripAudienceDetails as fetchEmailDripAudienceDetailsApi } from "../../EmailDrip.ApiCalls";
import { useListController } from "react-admin";
import EmailDripItem from "ui/pages/communications/CustomEmail/components/EmailDripItem/EmailDripItem";
import { emailDetailApiKeys, getFieldConfig } from "./analyticsPerEmailConfig";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import { app_pathnames, app_route_keys } from "constants/urlPaths";
import { emailLoaderProps } from "ui/pages/communications/CustomEmail/components/EmailDripItem/EmailDripItem.constants";
import EmailDripItemLoader from "ui/pages/communications/CustomEmail/components/EmailDripItem/EmailDripItemLoader";
import { EMAIL_DRIP_STATUS } from "../../EmailDrip.constants";

function AnalyticsPerEmail(props) {
  const isDesktop = useDesktopMediaQuery();
  useHideNavAndAppBar("Per email analytics");
  const { data, loading } = useListController(props);
  const { history, match } = props;
  const { dripUuid } = match.params;

  const [dripDetails, setDripDetails] = useState({});
  const emailStatsProps = emailLoaderProps(isDesktop)[dripDetails?.status];
  const isInactiveDrip = dripDetails?.status === EMAIL_DRIP_STATUS.INACTIVE;
  const ActionIcon = isInactiveDrip
    ? VisibilityOutlinedIcon
    : CreateOutlinedIcon;

  const listData = Object.values(data);
  const writeDisabled = !orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );

  useEffect(() => {
    async function fetchEmailDripAudienceDetails() {
      try {
        const details = await fetchEmailDripAudienceDetailsApi(dripUuid);
        setDripDetails(details);
      } catch (err) {
        console.log(err, "Err fetching drip details");
      }
    }

    fetchEmailDripAudienceDetails();
  }, [dripUuid]);

  function handleEdit(record) {
    if (writeDisabled) return;

    const showWarningModal =
      dripDetails?.status === EMAIL_DRIP_STATUS.ACTIVE ||
      dripDetails?.status === EMAIL_DRIP_STATUS.PAUSED;

    const query = { "edit-warn": true };
    const searchString = qs.stringify(query);

    history.push({
      pathname: app_pathnames[app_route_keys.email_drip_edit_email](
        dripUuid,
        record?.uuid
      ),
      search: showWarningModal ? searchString : "",
    });
  }

  return (
    <>
      {isDesktop && (
        <div className={style.header}>
          <GoBack title="Per email analytics" />
          <div className={style.subHeading}>{`Sequence / ${
            dripDetails?.title || ""
          } / View analytics per email`}</div>
        </div>
      )}
      <div className={style.listWrap}>
        {loading && <EmailDripItemLoader {...emailStatsProps} />}

        {!loading &&
          listData.map((item) => (
            <EmailDripItem
              data={item}
              key={item.uuid}
              fieldConfig={getFieldConfig({ dripDetails })}
              {...emailStatsProps}
              actions={[
                {
                  icon: <ActionIcon className={style.desktopActionIcon} />,
                  onClick: handleEdit,
                  label: isInactiveDrip ? "View Email" : "Edit Email",
                  disabled: writeDisabled,
                },
              ]}
              MobileActionIcon={(props) => (
                <ActionIcon className={style.mobileActionIcon} {...props} />
              )}
              onMobileActionsClick={handleEdit}
              writeDisabled={writeDisabled}
              handleEdit={handleEdit}
              HeaderIcon={MailOutlineRoundedIcon}
              headerFormatter={(data) => data[emailDetailApiKeys.subject]}
            />
          ))}
      </div>
    </>
  );
}

export default withComponentLibraryTheme(AnalyticsPerEmail);

import { DRIP_AUDIENCE_TYPE, EMAIL_SCHEDULE } from "../../EmailDrip.constants";

// enum used for trigger_type
export const SUBSCRIPTION_TRIGGER_TYPE = {
  TIME_BASED: 1,
  EVENT_BASED: 2,
};

// enum for audience_type
export const AUDIENCE_TYPE = {
  CUSTOMER: 1,
  LEAD: 2,
};

// enum for event_type
export const TRIGGER_EVENT_TYPE = {
  SUBSCRIPTION_EVENT: 1,
  EXCLUSION_EVENT: 2,
  UNSUBSCRIBE_EVENT: 3,
};

export const TRIGGER_VALUES = {
  BUYING_OFFERING: 1,
  CUSTOMER_OFFERING_START: 2,
  PAYMENT_INITIATED: 3,
  PAYMENT_FAILED: 4,
  GET_IN_TOUCH_FORM: 5,
  LEAD_OFFERING_START: 6,
  SESSION_END: 7,
  LEAD_SESSION_END: 8,
  EMAIL_BROADCAST_OPEN: 9,
  EMAIL_BROADCAST_LINK_CLICK: 10,
  DRIP_COMPLETED: 11,
  DRIP_EMAIL_SENT: 12,
  DRIP_EMAIL_OPEN: 13,
  DRIP_EMAIL_LINK_CLICK: 14,
  TAG_ADDED: 15,
  TAG_REMOVED: 16,
  INSTALMENT_PAYMENT_INITIATED: 17,
  INSTALMENT_PAYMENT_FAILS: 18,
};

export const TRIGGER_AUDIENCE_TYPE_MAP = {
  [TRIGGER_VALUES.BUYING_OFFERING]: DRIP_AUDIENCE_TYPE.CUSTOMERS,
  [TRIGGER_VALUES.CUSTOMER_OFFERING_START]: DRIP_AUDIENCE_TYPE.CUSTOMERS,
  [TRIGGER_VALUES.SESSION_END]: DRIP_AUDIENCE_TYPE.CUSTOMERS,
  [TRIGGER_VALUES.PAYMENT_INITIATED]: DRIP_AUDIENCE_TYPE.LEADS,
  [TRIGGER_VALUES.PAYMENT_FAILED]: DRIP_AUDIENCE_TYPE.LEADS,
  [TRIGGER_VALUES.LEAD_OFFERING_START]: DRIP_AUDIENCE_TYPE.LEADS,
  [TRIGGER_VALUES.LEAD_SESSION_END]: DRIP_AUDIENCE_TYPE.LEADS,
  [TRIGGER_VALUES.GET_IN_TOUCH_FORM]: undefined,
  [TRIGGER_VALUES.EMAIL_BROADCAST_OPEN]: undefined,
  [TRIGGER_VALUES.EMAIL_BROADCAST_LINK_CLICK]: undefined,
  [TRIGGER_VALUES.DRIP_COMPLETED]: undefined,
  [TRIGGER_VALUES.DRIP_EMAIL_SENT]: undefined,
  [TRIGGER_VALUES.DRIP_EMAIL_OPEN]: undefined,
  [TRIGGER_VALUES.DRIP_EMAIL_LINK_CLICK]: undefined,
  [TRIGGER_VALUES.TAG_ADDED]: undefined,
  [TRIGGER_VALUES.TAG_REMOVED]: undefined,
  [TRIGGER_VALUES.SEGMENT__CONTACT_IS_ADDED]: undefined,
};

export const TIME_BASED_TRIGGER_CATEGORIES = {
  START: "START",
  END: "END",
};

export const TRIGGER_TIME_BASED_CATEGORY_MAP = {
  [TRIGGER_VALUES.CUSTOMER_OFFERING_START]: TIME_BASED_TRIGGER_CATEGORIES.START,
  [TRIGGER_VALUES.SESSION_END]: TIME_BASED_TRIGGER_CATEGORIES.END,
  [TRIGGER_VALUES.LEAD_OFFERING_START]: TIME_BASED_TRIGGER_CATEGORIES.START,
  [TRIGGER_VALUES.LEAD_SESSION_END]: TIME_BASED_TRIGGER_CATEGORIES.END,
};

export const getDripScheduleOptions = (timeBasedCategoryType) => {
  const timingKey =
    timeBasedCategoryType === TIME_BASED_TRIGGER_CATEGORIES.START
      ? "starts"
      : "ends";
  return [
    {
      label: `Before each session ${timingKey}`,
      value: EMAIL_SCHEDULE.BEFORE_EVENT,
    },
    {
      label: `After each session ${timingKey}`,
      value: EMAIL_SCHEDULE.AFTER_EVENT,
    },
  ];
};

export const MODAL_OPEN_MODE = {
  ADD: "ADD",
  EDIT: "EDIT",
};

export const TRIGGER_LABELS = {
  [TRIGGER_VALUES.BUYING_OFFERING]: "Offering is purchased",
  [TRIGGER_VALUES.CUSTOMER_OFFERING_START]: "Offering starts",
  [TRIGGER_VALUES.SESSION_END]: "Offering ends",
  [TRIGGER_VALUES.PAYMENT_INITIATED]: "Payment is initiated for offering",
  [TRIGGER_VALUES.PAYMENT_FAILED]: "Payment fails for offering",
  [TRIGGER_VALUES.LEAD_OFFERING_START]: "Offering starts",
  [TRIGGER_VALUES.LEAD_SESSION_END]: "Offering ends",
  [TRIGGER_VALUES.GET_IN_TOUCH_FORM]: "Get in touch form is filled",
  [TRIGGER_VALUES.EMAIL_BROADCAST_OPEN]: "Email broadcast is opened",
  [TRIGGER_VALUES.EMAIL_BROADCAST_LINK_CLICK]:
    "Link in email broadcast is clicked",
  [TRIGGER_VALUES.DRIP_COMPLETED]: "Email drip is completed",
  [TRIGGER_VALUES.DRIP_EMAIL_SENT]: "Email in drip is sent",
  [TRIGGER_VALUES.DRIP_EMAIL_OPEN]: "Email in drip is opened",
  [TRIGGER_VALUES.DRIP_EMAIL_LINK_CLICK]: "Link in drip’s email is clicked",
  [TRIGGER_VALUES.TAG_ADDED]: "Tag is added",
  [TRIGGER_VALUES.TAG_REMOVED]: "Tag is removed",
  [TRIGGER_VALUES.INSTALMENT_PAYMENT_INITIATED]:
    "Instalment payment is initiated for offering",
  [TRIGGER_VALUES.INSTALMENT_PAYMENT_FAILS]:
    "Instalment payment fails for offering",
};

export const TRIGGER_TYPE_MAP = {
  [TRIGGER_VALUES.BUYING_OFFERING]: SUBSCRIPTION_TRIGGER_TYPE.EVENT_BASED,
  [TRIGGER_VALUES.CUSTOMER_OFFERING_START]:
    SUBSCRIPTION_TRIGGER_TYPE.TIME_BASED,
  [TRIGGER_VALUES.SESSION_END]: SUBSCRIPTION_TRIGGER_TYPE.TIME_BASED,
  [TRIGGER_VALUES.PAYMENT_INITIATED]: SUBSCRIPTION_TRIGGER_TYPE.EVENT_BASED,
  [TRIGGER_VALUES.PAYMENT_FAILED]: SUBSCRIPTION_TRIGGER_TYPE.EVENT_BASED,
  [TRIGGER_VALUES.LEAD_OFFERING_START]: SUBSCRIPTION_TRIGGER_TYPE.TIME_BASED,
  [TRIGGER_VALUES.LEAD_SESSION_END]: SUBSCRIPTION_TRIGGER_TYPE.TIME_BASED,
  [TRIGGER_VALUES.GET_IN_TOUCH_FORM]: SUBSCRIPTION_TRIGGER_TYPE.EVENT_BASED,
  [TRIGGER_VALUES.EMAIL_BROADCAST_OPEN]: SUBSCRIPTION_TRIGGER_TYPE.EVENT_BASED,
  [TRIGGER_VALUES.EMAIL_BROADCAST_LINK_CLICK]:
    SUBSCRIPTION_TRIGGER_TYPE.EVENT_BASED,
  [TRIGGER_VALUES.DRIP_COMPLETED]: SUBSCRIPTION_TRIGGER_TYPE.EVENT_BASED,
  [TRIGGER_VALUES.DRIP_EMAIL_SENT]: SUBSCRIPTION_TRIGGER_TYPE.EVENT_BASED,
  [TRIGGER_VALUES.DRIP_EMAIL_OPEN]: SUBSCRIPTION_TRIGGER_TYPE.EVENT_BASED,
  [TRIGGER_VALUES.DRIP_EMAIL_LINK_CLICK]: SUBSCRIPTION_TRIGGER_TYPE.EVENT_BASED,
  [TRIGGER_VALUES.TAG_ADDED]: SUBSCRIPTION_TRIGGER_TYPE.EVENT_BASED,
  [TRIGGER_VALUES.TAG_REMOVED]: SUBSCRIPTION_TRIGGER_TYPE.EVENT_BASED,
  [TRIGGER_VALUES.SEGMENT__CONTACT_IS_ADDED]:
    SUBSCRIPTION_TRIGGER_TYPE.EVENT_BASED,
};

export const ALL_TRIGGER_DROPDOWN_OPTIONS = [
  {
    id: 1,
    name: "Customer",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.BUYING_OFFERING],
        value: TRIGGER_VALUES.BUYING_OFFERING,
      },
      {
        id: 2,
        label: TRIGGER_LABELS[TRIGGER_VALUES.CUSTOMER_OFFERING_START],
        value: TRIGGER_VALUES.CUSTOMER_OFFERING_START,
      },
      {
        id: 3,
        label: TRIGGER_LABELS[TRIGGER_VALUES.SESSION_END],
        value: TRIGGER_VALUES.SESSION_END,
      },
    ],
  },
  {
    id: 2,
    name: "High Intent Leads",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.PAYMENT_INITIATED],
        value: TRIGGER_VALUES.PAYMENT_INITIATED,
      },
      {
        id: 2,
        label: TRIGGER_LABELS[TRIGGER_VALUES.PAYMENT_FAILED],
        value: TRIGGER_VALUES.PAYMENT_FAILED,
      },
      {
        id: 3,
        label: TRIGGER_LABELS[TRIGGER_VALUES.LEAD_OFFERING_START],
        value: TRIGGER_VALUES.LEAD_OFFERING_START,
      },
      {
        id: 4,
        label: TRIGGER_LABELS[TRIGGER_VALUES.LEAD_SESSION_END],
        value: TRIGGER_VALUES.LEAD_SESSION_END,
      },
      {
        id: 5,
        label: TRIGGER_LABELS[TRIGGER_VALUES.INSTALMENT_PAYMENT_INITIATED],
        value: TRIGGER_VALUES.INSTALMENT_PAYMENT_INITIATED,
      },
      {
        id: 6,
        label: TRIGGER_LABELS[TRIGGER_VALUES.INSTALMENT_PAYMENT_FAILS],
        value: TRIGGER_VALUES.INSTALMENT_PAYMENT_FAILS,
      },
    ],
  },
  {
    id: 3,
    name: "Low Intent Leads",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.GET_IN_TOUCH_FORM],
        value: TRIGGER_VALUES.GET_IN_TOUCH_FORM,
      },
    ],
  },
  {
    id: 4,
    name: "Email",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.EMAIL_BROADCAST_OPEN],
        value: TRIGGER_VALUES.EMAIL_BROADCAST_OPEN,
      },
      {
        id: 2,
        label: TRIGGER_LABELS[TRIGGER_VALUES.EMAIL_BROADCAST_LINK_CLICK],
        value: TRIGGER_VALUES.EMAIL_BROADCAST_LINK_CLICK,
      },
    ],
  },
  {
    id: 5,
    name: "Email Drip",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.DRIP_COMPLETED],
        value: TRIGGER_VALUES.DRIP_COMPLETED,
      },
    ],
  },
  {
    id: 6,
    name: "Email drip email",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.DRIP_EMAIL_SENT],
        value: TRIGGER_VALUES.DRIP_EMAIL_SENT,
      },
      {
        id: 2,
        label: TRIGGER_LABELS[TRIGGER_VALUES.DRIP_EMAIL_OPEN],
        value: TRIGGER_VALUES.DRIP_EMAIL_OPEN,
      },
      {
        id: 3,
        label: TRIGGER_LABELS[TRIGGER_VALUES.DRIP_EMAIL_LINK_CLICK],
        value: TRIGGER_VALUES.DRIP_EMAIL_LINK_CLICK,
      },
    ],
  },
  {
    id: 7,
    name: "Tag",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.TAG_ADDED],
        value: TRIGGER_VALUES.TAG_ADDED,
      },
      {
        id: 2,
        label: TRIGGER_LABELS[TRIGGER_VALUES.TAG_REMOVED],
        value: TRIGGER_VALUES.TAG_REMOVED,
      },
    ],
  },
];

export const TIME_BASED_TRIGGER_DROPDOWN_OPTIONS = [
  {
    id: 1,
    name: "Customer",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.CUSTOMER_OFFERING_START],
        value: TRIGGER_VALUES.CUSTOMER_OFFERING_START,
      },
      {
        id: 2,
        label: TRIGGER_LABELS[TRIGGER_VALUES.SESSION_END],
        value: TRIGGER_VALUES.SESSION_END,
      },
    ],
  },
  {
    id: 2,
    name: "High Intent Leads",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.LEAD_OFFERING_START],
        value: TRIGGER_VALUES.LEAD_OFFERING_START,
      },
      {
        id: 2,
        label: TRIGGER_LABELS[TRIGGER_VALUES.LEAD_SESSION_END],
        value: TRIGGER_VALUES.LEAD_SESSION_END,
      },
    ],
  },
];

export const EVENT_BASED_TRIGGER_DROPDOWN_OPTIONS = [
  {
    id: 1,
    name: "Customer",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.BUYING_OFFERING],
        value: TRIGGER_VALUES.BUYING_OFFERING,
      },
    ],
  },
  {
    id: 2,
    name: "High Intent Leads",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.PAYMENT_INITIATED],
        value: TRIGGER_VALUES.PAYMENT_INITIATED,
      },
      {
        id: 2,
        label: TRIGGER_LABELS[TRIGGER_VALUES.PAYMENT_FAILED],
        value: TRIGGER_VALUES.PAYMENT_FAILED,
      },
      {
        id: 3,
        label: TRIGGER_LABELS[TRIGGER_VALUES.INSTALMENT_PAYMENT_INITIATED],
        value: TRIGGER_VALUES.INSTALMENT_PAYMENT_INITIATED,
      },
      {
        id: 4,
        label: TRIGGER_LABELS[TRIGGER_VALUES.INSTALMENT_PAYMENT_FAILS],
        value: TRIGGER_VALUES.INSTALMENT_PAYMENT_FAILS,
      },
    ],
  },
  {
    id: 3,
    name: "Low Intent Leads",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.GET_IN_TOUCH_FORM],
        value: TRIGGER_VALUES.GET_IN_TOUCH_FORM,
      },
    ],
  },
  {
    id: 4,
    name: "Email",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.EMAIL_BROADCAST_OPEN],
        value: TRIGGER_VALUES.EMAIL_BROADCAST_OPEN,
      },
      {
        id: 2,
        label: TRIGGER_LABELS[TRIGGER_VALUES.EMAIL_BROADCAST_LINK_CLICK],
        value: TRIGGER_VALUES.EMAIL_BROADCAST_LINK_CLICK,
      },
    ],
  },
  {
    id: 5,
    name: "Email Drip",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.DRIP_COMPLETED],
        value: TRIGGER_VALUES.DRIP_COMPLETED,
      },
    ],
  },
  {
    id: 6,
    name: "Email drip email",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.DRIP_EMAIL_SENT],
        value: TRIGGER_VALUES.DRIP_EMAIL_SENT,
      },
      {
        id: 2,
        label: TRIGGER_LABELS[TRIGGER_VALUES.DRIP_EMAIL_OPEN],
        value: TRIGGER_VALUES.DRIP_EMAIL_OPEN,
      },
      {
        id: 3,
        label: TRIGGER_LABELS[TRIGGER_VALUES.DRIP_EMAIL_LINK_CLICK],
        value: TRIGGER_VALUES.DRIP_EMAIL_LINK_CLICK,
      },
    ],
  },
  {
    id: 7,
    name: "Tag",
    suboptions: [
      {
        id: 1,
        label: TRIGGER_LABELS[TRIGGER_VALUES.TAG_ADDED],
        value: TRIGGER_VALUES.TAG_ADDED,
      },
      {
        id: 2,
        label: TRIGGER_LABELS[TRIGGER_VALUES.TAG_REMOVED],
        value: TRIGGER_VALUES.TAG_REMOVED,
      },
    ],
  },
];

export const MAX_TIME_BASED_TRIGGERS_ALLOWED = 1;

export const MIN_EVENT_BASED_TRIGGERS_ALLOWED = 1;

import React from "react";
import moment from "moment";
import classnames from "classnames";
import {
  DRIP_FREQUENCY,
  DRIP_FREQUENCY_LABEL,
  EMAIL_SCHEDULE,
} from "../../EmailDrip.constants";
import {
  SUBSCRIPTION_TRIGGER_TYPE,
  TIME_BASED_TRIGGER_CATEGORIES,
  TRIGGER_TIME_BASED_CATEGORY_MAP,
} from "../../components/EmailTriggers/EmailTrigger.constants";
import { MINUTES_COUNT_IN_ONE_HOUR } from "constants/dateTime";
import { removeLastCharacterIfSingular } from "utils/string";

export function calculateMinutesUntilFutureTime(days, time) {
  const now = moment(); // Get the current moment
  const future = moment()
    .add(days, "days")
    .set({
      hour: moment(time, "hh:mm a").hour(),
      minute: moment(time, "hh:mm a").minute(),
    }); // Set the future moment to the specified day and time
  const minutesDiff = future.diff(now, "minutes"); // Calculate the difference between the future moment and the current moment in minutes
  return minutesDiff; // Return the number of minutes
}

export function calculateMinutesUntilPastTime(days, time) {
  const now = moment(); // Get the current moment
  const past = moment()
    .subtract(days, "days")
    .set({
      hour: moment(time, "hh:mm a").hour(),
      minute: moment(time, "hh:mm a").minute(),
    }); // Set the past moment to two days prior to the specified day and time
  const minutesDiff = past.diff(now, "minutes"); // Calculate the difference between the past moment and the current moment in minutes
  return minutesDiff; // Return the number of minutes
}

export function convertScheduleInMinutes({
  frequency,
  magnitude,
  schedule_time,
  schedule,
}) {
  if (frequency === DRIP_FREQUENCY.MINUTES) {
    return schedule === EMAIL_SCHEDULE.BEFORE_EVENT ? -magnitude : magnitude;
  }
  if (frequency === DRIP_FREQUENCY.HOURS) {
    const value = magnitude * MINUTES_COUNT_IN_ONE_HOUR;
    return schedule === EMAIL_SCHEDULE.BEFORE_EVENT ? -value : value;
  }
  if (frequency === DRIP_FREQUENCY.DAYS) {
    const manipulateFunction =
      schedule === EMAIL_SCHEDULE.BEFORE_EVENT
        ? calculateMinutesUntilPastTime
        : calculateMinutesUntilFutureTime;
    return manipulateFunction(magnitude, schedule_time);
  }
}

export function getSchedulePreview({
  email_frequency_type,
  magnitude,
  schedule_time,
  schedule,
  trigger_type,
  scheduleClassName,
  magnitudeClassName,
  scheduleTimeClassName,
  timeBasedCategoryType,
}) {
  const isFrequencyDayWise = email_frequency_type === DRIP_FREQUENCY.DAYS;
  const frequencyLabel = DRIP_FREQUENCY_LABEL[email_frequency_type];
  // showing 1 day, 2 days  ( removing s if magnitude is 1)
  const displayFrequencyLabel = removeLastCharacterIfSingular(
    magnitude,
    frequencyLabel
  );

  if (trigger_type === SUBSCRIPTION_TRIGGER_TYPE.EVENT_BASED) {
    return (
      <span>
        <span className={classnames(magnitudeClassName, "mr-1")}>
          {magnitude} {displayFrequencyLabel}
        </span>
        <span>from when they are subscribed to the drip campaign</span>
        {isFrequencyDayWise && (
          <span
            className={classnames("text-lowercase ml-1", scheduleTimeClassName)}
          >
            at {schedule_time}
          </span>
        )}
      </span>
    );
  }

  if (trigger_type === SUBSCRIPTION_TRIGGER_TYPE.TIME_BASED) {
    const isScheduleBeforeEvent = schedule === EMAIL_SCHEDULE.BEFORE_EVENT;
    const timingKey =
      timeBasedCategoryType === TIME_BASED_TRIGGER_CATEGORIES.START
        ? "starts"
        : "ends";

    return (
      <span>
        <span className={classnames(magnitudeClassName, "mr-1")}>
          {magnitude} {displayFrequencyLabel}
        </span>
        {isScheduleBeforeEvent ? (
          <span>
            <span className={classnames(scheduleClassName, "mr-1")}>
              before
            </span>
            <span>each session {timingKey}</span>
          </span>
        ) : (
          <span>
            <span className={classnames(scheduleClassName, "mr-1")}>after</span>
            <span>each session {timingKey}</span>
          </span>
        )}
        {isFrequencyDayWise && (
          <span
            className={classnames("text-lowercase ml-1", scheduleTimeClassName)}
          >
            at {schedule_time}
          </span>
        )}
      </span>
    );
  }
  return <></>;
}

// time based drip will only have single trigger, so getting trigger type from 1'st trigger
export const getTimeBasedCategoryType = (audienceDetails) =>
  TRIGGER_TIME_BASED_CATEGORY_MAP[
    audienceDetails?.trigger_data?.[0]?.subscribe_event
  ];

import { api, dataProvider } from "data";
import email_drip_api from "./EmailDrip.api";
import moment from "moment";

export const createDrip = async (title, drip_mode) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.create_drip,
    "POST",
    { title, drip_mode }
  );
  if (status !== 200 || !data?.drip_uid)
    throw new Error(`Error creating drip: ${data} ${status}`);
  return data.drip_uid;
};

export const fetchEmailDripAudienceDetails = async (dripUuid) => {
  const { data, status } = await dataProvider.custom_request(
    `${email_drip_api.get_email_drip_details}/${dripUuid}`
  );
  if (status !== 200 || !data?.drip_data)
    throw new Error(`Error fetching drip details: ${data} ${status}`);
  return data.drip_data;
};

export const fetchDripEmailList = async (drip_uid, page = "all") => {
  const { data, status } = await dataProvider.custom_request(
    `${email_drip_api.get_drip_email_list}`,
    "GET",
    { drip_uid, page }
  );
  if (status !== 200)
    throw new Error(`Error fetching drip email list: ${data} ${status}`);
  return data;
};

export const updateDripAudienceDetails = async (drip_uid, updated_values) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.update_drip_audience_details,
    "POST",
    { drip_uid, updated_values }
  );
  if (status !== 200)
    throw new Error(`Error updating drip details: ${data} ${status}`);
};

export const createNewEmailsInDrip = async (drip_uid, drip_campaign_data) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.create_email_in_drip,
    "POST",
    { drip_uid, drip_campaign_data }
  );
  if (status !== 200)
    throw new Error(`Error creating email in drip: ${data} ${status}`);
  return data;
};

export const updateEmailInDrip = async (
  email_campaign_uuid,
  updated_values
) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.update_email_in_drip,
    "POST",
    { email_campaign_uuid, updated_values }
  );
  if (status !== 200)
    throw new Error(`Error creating email in drip: ${data} ${status}`);
  return data;
};

export const deleteEmailInDrip = async (email_campaign_uuid) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.delete_email_in_drip,
    "POST",
    { email_campaign_uuid }
  );
  if (status !== 200)
    throw new Error(`Error creating email in drip: ${data} ${status}`);
  return data;
};

export const launchEmailDrip = async (drip_uid) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.launch_email_drip,
    "POST",
    { drip_uid }
  );
  if (status !== 200)
    throw new Error(`Error launching email drip: ${data} ${status}`);
  return data;
};

export const pauseEmailDrip = async (drip_uid) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.pause_email_drip,
    "POST",
    { drip_uid }
  );
  if (status !== 200)
    throw new Error(`Error pausing email drip: ${data} ${status}`);
  return data;
};

export const endEmailDrip = async (drip_uid) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.end_email_drip,
    "POST",
    { drip_uid }
  );
  if (status !== 200)
    throw new Error(`Error ending email drip: ${data} ${status}`);
  return data;
};

export const duplicateEmailDrip = async (drip_uid) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.duplicate_email_drip,
    "POST",
    { drip_uid }
  );
  if (status !== 200)
    throw new Error(`Error duplicating email drip: ${data} ${status}`);
  return data;
};

export const deleteEmailDrip = async (drip_uid) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.delete_emai_drip,
    "POST",
    { drip_uid }
  );
  if (status !== 200)
    throw new Error(`Error deleting email drip: ${data} ${status}`);
  return data;
};

export const fetchDripEmailDetails = async (uuid) => {
  const { data, status } = await dataProvider.custom_request(
    `${email_drip_api.get_drip_email_details}/${uuid}`
  );
  if (status !== 200 || !data?.email_drip_data)
    throw new Error(`Error fetching drip details: ${data} ${status}`);
  return data.email_drip_data;
};

export const resetDripSchedules = async (drip_uid) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.reset_drip_schedules,
    "POST",
    { drip_uid }
  );
  if (status !== 200)
    throw new Error(`Error Reseting drip schedule: ${data} ${status}`);
  return data;
};

export const fetchDripList = async ({
  dripStatus,
  page,
  trigger_type,
  exclude_drip_uuid,
}) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.get_email_drip_list,
    "GET",
    { status: dripStatus, page, trigger_type, exclude_drip_uuid }
  );
  if (status !== 200)
    throw new Error(`Error fetching email drips: ${data} ${status}`);
  return data;
};

// Email Trigger apis
export const fetchNofificationTriggerDetailsApi = async (dripUuid) => {
  const { data, status } = await dataProvider.custom_request(
    `${email_drip_api.get_notification_trigger_details}/${dripUuid}`,
    "GET"
  );
  if (status !== 200)
    throw new Error(
      `Error fetching email drip trigger details: ${data} ${status}`
    );
  return data;
};

export const fetchDripTriggersApi = async (dripUuid) => {
  const { data, status } = await dataProvider.custom_request(
    `${email_drip_api.get_drip_triggers}/${dripUuid}`,
    "GET"
  );
  if (status !== 200)
    throw new Error(
      `Error fetching email drip trigger details: ${data} ${status}`
    );
  return data;
};

export const createDripTrigger = async (drip_uid, trigger_data) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.create_drip_trigger,
    "POST",
    { drip_uid, trigger_data }
  );
  if (status !== 200)
    throw new Error(`Error creating drip trigger: ${data} ${status}`);
  return data;
};

export const updateDripTrigger = async (trigger_uuid, updated_values) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.update_drip_trigger,
    "POST",
    { trigger_uuid, updated_values }
  );
  if (status !== 200)
    throw new Error(`Error updating drip trigger: ${data} ${status}`);
  return data;
};

export const deleteDripTrigger = async (trigger_uuid) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.delete_drip_trigger,
    "POST",
    { trigger_uuid }
  );
  if (status !== 200)
    throw new Error(`Error deleting drip trigger: ${data} ${status}`);
  return data;
};

export const createDripCondition = async (payload) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.create_condition,
    "POST",
    payload
  );
  if (status !== 200)
    throw new Error(`Error creating drip condition: ${data} ${status}`);
  return data;
};

export const updateDripCondition = async (condition_uuid, updated_values) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.update_condition,
    "POST",
    { condition_uuid, updated_values }
  );
  if (status !== 200)
    throw new Error(`Error updating drip condition: ${data} ${status}`);
  return data;
};

export const deleteDripCondition = async (condition_uuid) => {
  const { data, status } = await dataProvider.custom_request(
    email_drip_api.delete_condition,
    "POST",
    { condition_uuid }
  );
  if (status !== 200)
    throw new Error(`Error deleting drip condition: ${data} ${status}`);
  return data;
};

// Class and workshop releated api calls

export const fetchClassesAndRollingClassesList = async (offeringUuid) => {
  const { data, status } = await dataProvider.custom_request(
    `${api.get_listing_details}/${offeringUuid}`,
    "GET"
  );
  if (status !== 200)
    throw new Error(`Error fetching classes: ${data} ${status}`);
  return data.event.classes;
};

export const fetchWorkshopSesssions = async ({
  listing_uuid,
  start_date = moment(new Date()).format("YYYY-MM-DD"),
  end_date = moment(new Date()).add(60, "d").format("YYYY-MM-DD"),
}) => {
  const { data, status } = await dataProvider.custom_request(
    api.listingSpecificCommunication.get_offering_details_sessions,
    "GET",
    { start_date, end_date, listing_uuid: listing_uuid }
  );
  if (status !== 200)
    throw new Error(`Error fetching workshop sessions: ${data} ${status}`);
  return data.sessions;
};
